import { createSlice } from '@reduxjs/toolkit';

import {
  getChecklists,
  getCompanyRoles,
  getEventTypes,
  getIndustryBranches,
  getMeasures,
  getMeasureTypes,
  getSendEmailSubtypes,
  getStandardCommentCategories,
  getVisitSubTypes,
} from 'ducks/constants/actions';
import { ChecklistType, ConstantsState } from 'ducks/constants/types';
import { getTipsThemes } from './actions';

export const initialState: ConstantsState = {
  activeChecklists: [],
  checklists: [],
  checklistsStatus: 'idle',
  checkpoints: [],
  checkpointsInActiveChecklist: [],
  companyRoles: [],
  companyRolesStatus: 'idle',
  industryBranches: [],
  industryBranchesStatus: 'idle',
  measures: [],
  measuresStatus: 'idle',
  measureTypes: [],
  measureTypesStatus: 'idle',
  standardCommentCategories: [],
  standardComments: {},
  standardCommentsStatus: 'idle',
  revisedActivityTypes: [],
  revisedActivityTypesStatus: 'idle',
  sendEmailSubtypes: [],
  sendEmailSubtypesStatus: 'idle',
  tipsThemes: [],
  tipsThemesStatus: 'idle',
  visitSubTypes: [],
  visitSubTypesStatus: 'idle',
};

export const prepareChecklist = (payload: ChecklistType[]) => {
  const activeChecklists = payload.filter(
    (checklist) => checklist.active && checklist.checkpoints.some(({ active }) => active),
  );

  const checkpoints = payload.flatMap(({ checkpoints }) => checkpoints);
  const checkpointsInActiveChecklist = activeChecklists
    ?.filter(({ active }) => active)
    ?.flatMap(({ checkpoints }) => checkpoints);

  return { payload, activeChecklists, checkpoints, checkpointsInActiveChecklist };
};

const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyRoles.pending, (state) => {
      state.companyRolesStatus = 'pending';
    });
    builder.addCase(getCompanyRoles.fulfilled, (state, { payload }) => {
      state.companyRoles = payload;
      state.companyRolesStatus = 'resolved';
    });

    builder.addCase(getChecklists.pending, (state) => {
      state.checklistsStatus = 'pending';
    });
    builder.addCase(getChecklists.fulfilled, (state, { payload }) => {
      const preparedChecklists = prepareChecklist(payload);

      state.checklists = payload;
      state.checkpoints = preparedChecklists.checkpoints;

      state.activeChecklists = preparedChecklists.activeChecklists;
      state.checkpointsInActiveChecklist = preparedChecklists.checkpointsInActiveChecklist;
      state.checklistsStatus = 'resolved';
    });

    builder.addCase(getMeasures.pending, (state) => {
      state.measuresStatus = 'pending';
    });
    builder.addCase(getMeasures.fulfilled, (state, { payload }) => {
      state.measures = payload.map(({ id, ...rest }) => ({ ...rest, id: id.toString() }));
      state.measuresStatus = 'resolved';
    });

    builder.addCase(getIndustryBranches.pending, (state) => {
      state.industryBranchesStatus = 'pending';
    });
    builder.addCase(getIndustryBranches.fulfilled, (state, { payload }) => {
      state.industryBranches = payload;
      state.industryBranchesStatus = 'resolved';
    });

    builder.addCase(getMeasureTypes.pending, (state) => {
      state.measureTypesStatus = 'pending';
    });
    builder.addCase(getMeasureTypes.fulfilled, (state, { payload }) => {
      state.measureTypes = payload.map(({ id, name }) => ({ id: id.toString(), name }));
      state.measureTypesStatus = 'resolved';
    });

    builder.addCase(getStandardCommentCategories.pending, (state) => {
      state.measureTypesStatus = 'pending';
    });
    builder.addCase(getStandardCommentCategories.fulfilled, (state, { payload }) => {
      state.standardCommentCategories = payload.map(({ standardComments, ...rest }) => {
        const comments = standardComments.map(({ id }) => id);
        return { ...rest, standardComments: comments };
      });

      const standardComments = payload
        .flatMap(({ standardComments }) => standardComments)
        .reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {});
      state.standardComments = standardComments;
      state.standardCommentsStatus = 'resolved';
    });

    builder.addCase(getEventTypes.pending, (state) => {
      state.measureTypesStatus = 'pending';
    });
    builder.addCase(getEventTypes.fulfilled, (state, { payload }) => {
      state.revisedActivityTypes = payload;
      state.revisedActivityTypesStatus = 'resolved';
    });

    builder.addCase(getSendEmailSubtypes.pending, (state) => {
      state.sendEmailSubtypesStatus = 'pending';
    });
    builder.addCase(getSendEmailSubtypes.fulfilled, (state, { payload }) => {
      state.sendEmailSubtypes = payload;
      state.sendEmailSubtypesStatus = 'resolved';
    });
    builder.addCase(getTipsThemes.fulfilled, (state, { payload }) => {
      state.tipsThemes = payload;
      state.tipsThemesStatus = 'resolved';
    });
    builder.addCase(getVisitSubTypes.fulfilled, (state, { payload }) => {
      state.visitSubTypes = payload;
      state.visitSubTypesStatus = 'resolved';
    });
    builder.addCase(getVisitSubTypes.rejected, (state, { payload }) => {
      state.visitSubTypesStatus = 'rejected';
    });
  },
});

const { reducer: constantsReducer } = constantsSlice;

export { constantsReducer };
