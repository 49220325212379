import React from 'react';
import { navigate } from '@reach/router';
import { Form, FormSpy } from 'react-final-form';
import i18n from 'i18n';
import { BASE_URL } from 'api';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { StyledModalButtonsWrapper, StyledModalMessage, useModal } from 'components/Modal/Modal';
import {
  CompanyVisitResponse,
  createNewVisitCompanyVisit,
  getVisitById,
  NewVisitContact,
  NewVisitResponse,
  setStep3Contact,
  setWorkplaceIdToVisit,
  updateSameAsCompanyVisit,
} from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { SerializedError, unwrapResult } from '@reduxjs/toolkit';
import { Margin } from 'styles/utils';
import { StyledCheckboxWrapper, StyledError } from 'components/Forms/styles';
import { NestedCheckbox } from 'components/NextedCheckbox/NestedCheckbox';
import { StyledCheckboxRow, StyledContactsRow, StyledForm } from './DashboardStyles';
import { StyledHorizontalRule } from 'containers/CompanyCreate/styles';
import { FORM_ERROR } from 'final-form';
import { useSelector } from 'react-redux';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';

const t = (x: string) => i18n.t(x);

interface CopyVisitsFormProps {
  callback?: (id: string | number) => void | Promise<unknown>;
  visitId: string | number;
  originalVisitId: string;
  initialValues: fetchedCompanyVisit[];
  loading: boolean;
  setLoading: Function;
}

interface fetchedCompanyVisit extends CompanyVisitResponse {
  checked: boolean;
  contacts: fetchedContacts[];
}

interface fetchedContacts extends NewVisitContact {
  checked?: boolean;
}

export const CopyVisitsForm = (props: CopyVisitsFormProps): JSX.Element => {
  const { originalVisitId, visitId, initialValues, loading, setLoading } = props;
  const [showContacts, setShowContacts] = React.useState<Record<string, boolean>[]>([]);
  const [companyVisitsSelected, setCompanyVisitsSelected] = React.useState<
    Record<string, boolean>[]
  >([]);
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();
  const localhost = window.location.host === 'localhost:4000';
  const allDistricts = useSelector(allFilteredDistrictsSelector).map((district) => district.value);

  React.useEffect(() => {
    setLoading(true);
    setShowContacts(
      initialValues.map(() => {
        return { showing: true };
      }),
    );
    setCompanyVisitsSelected(
      initialValues.map(() => {
        return { checked: true };
      }),
    );
    setLoading(false);
  }, [initialValues]);

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true);
      // eslint-disable-next-line array-callback-return
      const selectedCompanyVisits = values.filter((valueVisit: fetchedCompanyVisit) => {
        if (valueVisit.checked === true) {
          valueVisit.contacts = valueVisit.contacts.filter(
            (contact: fetchedContacts) => contact.checked === true,
          );
          return valueVisit;
        }
      });
      if (selectedCompanyVisits.length > 0) {
        await dispatch(getVisitById({ id: originalVisitId! }))
          .then(unwrapResult)
          .then((fetchedVisit: NewVisitResponse) => {
            fetchedVisit.workplaceId
              ? dispatch(
                  setWorkplaceIdToVisit({
                    workplaceId: fetchedVisit.workplaceId,
                    visitId: visitId,
                  }),
                )
              : dispatch(
                  updateSameAsCompanyVisit({
                    address: fetchedVisit.addressForVisiting,
                    visit_id: visitId.toString(),
                  }),
                );
          })
          .then(() =>
            selectedCompanyVisits.map(async (companyVisit: fetchedCompanyVisit) => {
              await dispatch(
                createNewVisitCompanyVisit({
                  company_id: companyVisit.companyId,
                  visit_id: String(visitId),
                }),
              )
                .then(unwrapResult)
                .then((newCompanyVisit: CompanyVisitResponse) => {
                  if (companyVisit.contacts.length > 0) {
                    companyVisit.contacts.forEach(
                      async (contact: fetchedContacts) =>
                        await dispatch(
                          setStep3Contact({
                            company_id: contact.companyId,
                            company_visit_id: newCompanyVisit.id,
                            email: contact.email,
                            include_in_report: contact.includeInReport,
                            name: contact.name,
                            obligatory_training: contact.obligatoryTraining,
                            origin: contact.origin,
                            phone: contact.phone,
                            receive_visit_report: contact.receiveVisitReport,
                            role_id: contact.roleId,
                            visit_id: visitId,
                            id: contact.id,
                          }),
                        ),
                    );
                  }
                });
            }),
          )
          .then(async () => {
            //not ideal, but it was loading the page without the visitation address otherwise
            setTimeout(() => {
              hideModal();
              setLoading(false);
              navigate(
                localhost
                  ? `http://localhost:4000/redesigned/new-ordinary-visit/${visitId}/companies`
                  : `${BASE_URL}/redesigned/new-ordinary-visit/${visitId}/companies`,
                {
                  state: {
                    ...window.history.state,
                    previousPathname: window.location.pathname,
                    districtNames: {
                      type: 'string_array',
                      values: allDistricts,
                    },
                    previousDistrictNames: window.history.state.districtNames,
                  },
                },
              );
            }, 2000);
          })
          .catch((err: SerializedError) => {
            setLoading(false);
            return { [FORM_ERROR]: err.message };
          });
      }
    },
    [visitId],
  );

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Margin bottom='2.4rem'>
              <StyledHorizontalRule />
            </Margin>
            <StyledForm>
              {values.length > 0 &&
                values.map((companyVisit: CompanyVisitResponse, index: number) => {
                  return (
                    <div key={companyVisit.id}>
                      <NestedCheckbox
                        isDisabled={
                          companyVisitsSelected[index].checked &&
                          !values
                            .filter(
                              (value: CompanyVisitResponse) =>
                                value.companyId !== values[index].companyId,
                            )
                            .some((value: Record<string, boolean>) => value.checked === true)
                        }
                        label={companyVisit?.companyName}
                        onClick={() => {
                          values[index].checked = !values[index].checked;
                          const newShowContacts = [...showContacts];
                          newShowContacts[index].showing = !showContacts[index].showing;
                          setShowContacts([...newShowContacts]);
                          const newSelected = [...companyVisitsSelected];
                          newSelected[index].showing = !companyVisitsSelected[index].showing;
                          setCompanyVisitsSelected([...newSelected]);
                        }}
                      />
                      <StyledCheckboxRow>
                        {companyVisit.contacts.length > 0 && (
                          <>
                            <StyledContactsRow>
                              <StyledModalMessage>
                                {t('table.columns.contacts')}:
                              </StyledModalMessage>
                              {companyVisit.contacts.map((contact, contactIndex) => (
                                <div key={contact.id}>
                                  <StyledCheckboxWrapper>
                                    <NestedCheckbox
                                      isDisabled={!showContacts[index].showing}
                                      label={contact.name}
                                      onClick={() => {
                                        values[index].contacts[contactIndex].checked =
                                          !values[index].contacts[contactIndex]?.checked;
                                      }}
                                    />
                                  </StyledCheckboxWrapper>
                                </div>
                              ))}
                            </StyledContactsRow>
                          </>
                        )}
                      </StyledCheckboxRow>
                      {index + 1 < values.length && (
                        <Margin bottom='2.4rem' top='2.4rem'>
                          <StyledHorizontalRule />
                        </Margin>
                      )}
                    </div>
                  );
                })}
              <Margin margin='1.2rem auto'>
                {submitError && <StyledError>{submitError}</StyledError>}
              </Margin>
              <StyledModalButtonsWrapper>
                <DefaultButton
                  capitalize
                  label={t('common.cancel')}
                  onClick={hideModal}
                  type='button'
                  variant='tertiary'
                />
                <FormSpy subscription={{ errors: true }}>
                  {() => (
                    <DefaultButton
                      capitalize
                      isDisabled={loading}
                      label={t('common.copy')}
                      onClick={handleSubmit}
                      type='submit'
                    />
                  )}
                </FormSpy>
              </StyledModalButtonsWrapper>
            </StyledForm>
          </form>
        )}
      />
    </>
  );
};
