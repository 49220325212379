const { BACKEND_URL } = window.REACT_APP_ENV || 'http://localhost:3000';

const getApiHostFromOrigin = (): string => {
  const { origin } = window.location;
  return origin.replace('react/', '');
};

export const API_VERSION = 'v1';
export const API_PREFIX = `/api/${API_VERSION}`;
export const BASE_URL = BACKEND_URL || getApiHostFromOrigin();
export const API_BASE_URL = `${BASE_URL}${API_PREFIX}`;
